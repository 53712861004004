<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12">
              <v-row>
                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    label="Santral"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ santralModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-select v-model="reportTypeModel" :items="reportType" />
                </v-col>

                <v-col
                  v-if="
                    reportTypeModel === 'Monthly' || reportTypeModel === 'Daily'
                  "
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select v-model="yearModel" :items="yearList" label="Yıl" />
                </v-col>

                <v-col
                  v-if="reportTypeModel === 'Daily'"
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select
                    v-model="monthModel"
                    :items="monthList"
                    label="Ay"
                  />
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-menu
                    v-if="['Minute', 'Hourly'].indexOf(reportTypeModel) > -1"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Tarih"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <!--  <v-flex xs1 sm1 md1 class="pr-3">
                  <v-select
                    v-model="reportTypeModel"
                    :items="reportType"
                  ></v-select>
                </v-flex> -->

                <v-spacer />

                <!--   <v-flex xs1 sm1 md1 class="pr-3">
                  <v-select
                    v-model="chartTypeModel"
                    :items="chartType"
                    label="Grafik"
                  ></v-select>
                </v-flex> -->
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-col cols="2" sm="2" md="2" class="ml-5 pr-3 mt-4">
                  <v-select
                    v-model="inverter"
                    :items="inverterList"
                    label="İnverter"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item.text }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ inverter.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="2" sm="2" md="2" class="pr-3 mt-4">
                  <v-select
                    v-model="combinerBox"
                    :items="combinerboxList"
                    label="String"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item.text }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ combinerBox.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="3" sm="3" md="3" class="pr-3 mt-4">
                  <v-select
                    v-model="measuresModel"
                    :items="measures"
                    label="String"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        dense
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item.text }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ measuresModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
                  <v-btn color="primary" @click="getSantralDataChart">
                    Filtrele
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-col class="col-12 mt-2 ml-0 pl-0 mr-0 pr-0">
        <v-card>
          <div
            id="general-power-santral-comparison-stats"
            style="height: 300px; min-width: 900px; width: 100%; padding: 1px"
          />
        </v-card>
      </v-col>
    </v-container>

    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="color"
        :top="y === 'top'"
        :bottom="y === 'bottom'"
        :right="x === 'right'"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :vertical="mode === 'vertical'"
      >
        {{ text }}
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import moment from "moment";
import * as SantralComporison from "@/api/Analysis/SantralComparison";

import {
  stringboxMinute,
  stringboxHourly,
  stringboxDaily,
  stringboxMonthly,
  stringboxYearly,
} from "@/api/Request/stringbox";

import {
  inverterHourly,
  inverterMinute,
  inverterDaily,
  inverterMonthly,
  inverterYearly,
} from "@/api/Request/inverter";

export default {
  name: "SantralComparisonComponent",

  data: () => ({
    chartData: {},
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    menu: false,
    modal: false,
    menu2: false,

    itemsPerPage: 500,
    // eslint-disable-next-line radix
    yearModel: parseInt(moment().format("YYYY")),
    // eslint-disable-next-line radix
    monthModel: parseInt(moment().format("MM")),
    measures: [],
    chartType: ["line", "bar"],
    chartTypeModel: "line",
    componentKey: 1,
    headers: [],
    desserts: [],
    reportTypeModel: "Minute",
    reportType: [],
    weekListModel: 10,
    weekList: [],
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    santralDataTable: "Santral_Comparison_table",
    chartText: "",
    newData: "true",
    santralModel: [],

    analiysis_type: [
      { text: "Genel İstatistik", value: "generalStats" },
      { text: "İnverter", value: "INVERTER" },
      { text: "Stringbox", value: "STRINGBOX" },
    ],
    analiysis_typeModel: "generalStats",
    combinerBox: [],
    // santralModel: [""],
    santralInfo: [],
    inverter: [],
    inverter_measureList: [],
    stringbox_measureList: [],
    measuresModel: [],
    snackbar: false,
    y: "top",
    x: null,
    mode: "",
    timeout: 6000,
    color: "red",
    text: "sdsd",
    combinerboxList: [],
    inverterList: [],
    stepTime: 10,
    dates: [moment().format("YYYY-MM-DD")],
    santralInverterMeasure: {},
    santralStringMeasure: {},
    santralInverter: {},
    santralString: {},
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      // eslint-disable-next-line consistent-return
      return santralDataList;
    },

    yearList() {
      return this.$store.getters.clientYearList;
    },
    monthList() {
      return this.$store.getters.month;
    },
  },
  watch: {
    santralModel() {
      const { santralInverterData, santralStringboxData } = this.$store.getters;
      let stringlist = [];
      this.santralModel.forEach((santral) => {
        const stringname = [];
        Object.keys(santralStringboxData[santral]).forEach((i) => {
          const inv = santralStringboxData[santral][i].STRINGBOX;
          const invName = santralStringboxData[santral][i].STRINGBOXNAME;

          stringname.push({
            text: invName,
            value: inv,
          });
        });
        stringlist = stringlist.concat(stringname);
      });
      this.combinerboxList = stringlist;
      let inverterName = [];
      this.santralModel.forEach((santral) => {
        const inverterList = [];
        Object.keys(santralInverterData[santral]).forEach((i) => {
          const inv = santralInverterData[santral][i].INVERTER;
          const invName = santralInverterData[santral][i].INVERTERNAME;

          inverterList.push({
            text: invName,
            value: inv,
          });
        });
        inverterName = inverterName.concat(inverterList);
      });
      this.inverterList = inverterName;
    },
  },
  created() {},
  mounted() {
    this.reportType = [
      { text: "Dakika", value: "Minute" },
      { text: "Saatlik", value: "Hourly" },
      { text: "Günlük", value: "Daily" },
      { text: "Aylık", value: "Monthly" },
      { text: "Yıllık", value: "Yearly" },
    ];

    const self = this;
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);
    self.santralInfo = {
      INVERTER: {},
      STRINGBOX: {},
    };

    let k = 0;
    function myTimer1() {
      k += 1;
      if (k > 30) {
        clearInterval(myVar1);
      }

      const { santral } = self.$store.getters.selectedSantralModel;
      console.log(santral);
      const { stepTime, santralInverterData } = self.$store.getters;

      if (
        Object.keys(santralInverterData).length > 0 &&
        santral !== undefined
      ) {
        self.santralModel = [santral];
        self.stepTime = stepTime;

        clearInterval(myVar1);
        self.setInverters();
      }
    }
  },
  methods: {
    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },
    setInverters() {
      const { santralInverterData, inverterMeasureList } = this.$store.getters;
      this.chartData = {};
      const santral = this.santralModel[0];
      this.inverter = [Object.keys(santralInverterData[santral])[0]];
      this.measuresModel = [inverterMeasureList[0].value];
      this.inverter_measureList = inverterMeasureList;
      this.setStringMeasure();
    },

    setStringMeasure() {
      const { santralStringboxData, stringboxMeasureList } =
        this.$store.getters;
      const santral = this.santralModel[0];
      this.combinerBox = [Object.keys(santralStringboxData[santral])[0]];
      this.stringboxMeasureList = stringboxMeasureList;
      this.measuresModel.push(stringboxMeasureList[0].value);
      this.measures = this.stringbox_measureList.concat(
        this.inverter_measureList
      );
    },

    getSantralDataChart() {
      this.chartText = "";
      const measureCount = this.measuresModel.length;
      const santralCount = this.santralModel.length;
      const inverterCount = this.inverter.length;
      const combinerBoxCount = this.combinerBox.length;

      let count = 1;
      if (santralCount > 0) {
        // eslint-disable-next-line operator-assignment
        count = santralCount * count;
      }
      if (measureCount > 0) {
        // eslint-disable-next-line operator-assignment
        count = measureCount * count;
      }

      if (inverterCount > 0) {
        // eslint-disable-next-line operator-assignment
        count = inverterCount * count;
      }

      if (combinerBoxCount > 0) {
        // eslint-disable-next-line operator-assignment
        count = combinerBoxCount * count;
      }

      this.finishDate = moment(this.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

      const inverterMeasures = [];
      const inverter_Measures = [];

      Object.keys(this.inverter_measureList).forEach((i) => {
        if (
          this.measuresModel.indexOf(this.inverter_measureList[i].value) > -1
        ) {
          inverterMeasures.push(this.inverter_measureList[i].value);
          inverter_Measures.push(this.inverter_measureList[i]);
        }
      });

      const stringMeasures = [];
      const string_Measures = [];
      Object.keys(this.stringbox_measureList).forEach((i) => {
        if (
          this.measuresModel.indexOf(this.stringbox_measureList[i].value) > -1
        ) {
          stringMeasures.push(this.stringbox_measureList[i].value);
          string_Measures.push(this.stringbox_measureList[i]);
        }
      });

      const formdata = this.selectedParams(stringMeasures, inverterMeasures);

      const params = {
        string: formdata.string,
        inverter: formdata.inverter,
        text: this.chartText,
        chartTypeModel: this.chartTypeModel,
        santralModel: this.santralModel,
        reportTypeModel: this.reportTypeModel,
      };

      this.chartData = [];
      const self = this;
      let inverter;
      let string;

      if (this.reportTypeModel === "Minute") {
        inverter = inverterMinute(formdata.inverter);
        string = stringboxMinute(formdata.string);

        Promise.all([string, inverter]).then((_rawData) => {
          const tempData =
            SantralComporison.default.getSantralComparisonMinuteChart(
              self,
              params,
              _rawData,
              inverter_Measures,
              string_Measures,
              this.stepTime
            );
          this.setOpt(tempData);
        });
      }

      if (this.reportTypeModel === "Hourly") {
        inverter = inverterHourly(formdata.inverter);
        string = stringboxHourly(formdata.string);

        Promise.all([string, inverter]).then((_rawData) => {
          const tempData =
            SantralComporison.default.getSantralComparisonHourlyChart(
              self,
              params,
              _rawData,
              inverter_Measures,
              string_Measures
            );
          this.setOpt(tempData);
        });
      }

      if (this.reportTypeModel === "Daily") {
        inverter = inverterDaily(formdata.inverter);
        string = stringboxDaily(formdata.string);

        Promise.all([string, inverter]).then((_rawData) => {
          const tempData =
            SantralComporison.default.getSantralComparisonDailyChart(
              self,
              params,
              _rawData,
              inverter_Measures,
              string_Measures
            );
          this.setOpt(tempData);
        });
      }

      if (this.reportTypeModel === "Monthly") {
        inverter = inverterMonthly(formdata.inverter);
        string = stringboxMonthly(formdata.string);

        Promise.all([string, inverter]).then((_rawData) => {
          const tempData =
            SantralComporison.default.getSantralComparisonMonthlyChart(
              self,
              params,
              _rawData,
              inverter_Measures,
              string_Measures
            );
          this.setOpt(tempData);
        });
      }

      if (this.reportTypeModel === "Yearly") {
        inverter = inverterYearly(formdata.inverter);
        string = stringboxYearly(formdata.string);

        Promise.all([string, inverter]).then((_rawData) => {
          const tempData =
            SantralComporison.default.getSantralComparisonYearlyChart(
              self,
              params,
              _rawData,
              inverter_Measures,
              string_Measures
            );
          this.setOpt(tempData);
        });
      }
    },
    setOpt(tempData) {
      const chartDom = document.getElementById(
        "general-power-santral-comparison-stats"
      );
      const myChart = echarts.init(chartDom);
      tempData.then((res) => {
        myChart.setOption(res.options, true);

        // this.chartData = res.options;
        this.componentKey += 1;
        this.forceRerender();
      });
    },

    selectedParams(stringMeasures, inverterMeasures) {
      const { reportTypeModel } = this;
      let params;
      const inverter = {
        condiniton: {
          measures: inverterMeasures,
          INVERTER: this.inverter,
          santral: this.santralModel,
          maxcount: 3500,
        },
      };
      const string = {
        condiniton: {
          measures: stringMeasures,
          STRINGBOX: this.combinerBox,
          santral: this.santralModel,
          maxcount: 3500,
        },
      };

      if (reportTypeModel == "Hourly" || reportTypeModel == "Minute") {
        this.santralDataTable = `${this.startDate} - ${this.finishDate}-santralDataTable`;

        params = {
          startDate: this.startDate,
          finishDate: this.finishDate,
        };
      }

      if (reportTypeModel == "Daily") {
        this.santralDataTable = `${this.yearModel} ${this.monthModel}-santralAnalysisDataTable`;

        params = {
          year: this.yearModel,
          month: this.monthModel,
        };
      }

      if (reportTypeModel == "Monthly") {
        this.santralDataTable = `${this.yearModel} -santralAnalysisDataTable`;
        const monthL = [];
        for (let i = 1; i < 13; i++) {
          monthL.push(i);
        }
        params = {
          date: [
            {
              year: this.yearModel,
              month: monthL,
            },
          ],
        };
      }

      if (reportTypeModel == "Yearly") {
        this.santralDataTable = `${this.yearList[0]} -santralAnalysisDataTable`;

        params = {
          date: this.yearList,
        };
      }

      inverter.condiniton = { ...inverter.condiniton, ...params };
      string.condiniton = { ...string.condiniton, ...params };

      return {
        inverter,
        string,
      };
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
